/* eslint-disable import/prefer-default-export */
import ReactDOM from 'react-dom'
import $ from 'jquery'
import _ from 'lodash'
import * as Sentry from "@sentry/react"
// UTILS
import { currentServiceType, isCustomerEditBooking } from 'utils/new_booking/common'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// API
import PaymentIntegrationAPI from 'api/payment-integration'
// ACTIONS
import {
  getExtraServicesSingle,
  setUpBookingLocationForExtraRequirementPerLocation
} from './extraServiceActionCreators'
import {
  calculate,
  create,
  editBooking,
  addToFavoriteDrivers,
  updateBookingAction,
  getBookingPaymentDetailAction,
} from '../common/bookingActionCreators'
import { getCustomerCreditAmount } from '../common/customerActionCreators'

// COMPONENTS
import DynamicPopupWithButton from 'components/common/DynamicPopupWithButton'
// CONSTANTS
import {
  FULL_DAY, LONG_HAUL, PRICING_PAYMENT_INCREASE, PAYMENT_VA_STATUS, CUSTOMER_PAYMENT,
  CUSTOMER_QUOTE_PAYMENT, TWO_C_TWO_P_TYPE, PROVIDER_TYPE_PAYMENT_LIST, SPIL_TYPE, METHOD_CASH, SECTION_TYPE_PAYMENT_LIST,
} from 'constants/bookingConstants'
import { BATCH_STATUS_COMPLETED, BATCH_STATUS_CANCELED } from 'constants/ezSpreadSheetConstants'
// ASSETS
import {
  ICON_QUOTE_ID, ICON_DRIVER_PREFERENCES, ICON_CANCEL_BOOKING, V2_ICON_GREEN,
} from 'constants/imageConstants'
import { isEditBooking, isPaymentBooking } from 'utils/booking/common'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer';
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer';
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer';
import { tmpLocationActionsCreator } from 'store/toolkit/newBooking/updateTmpLocation.reducer';
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer';
import { stepNewLongHaulActionsCreator } from 'store/toolkit/newBooking/stepNewLongHaul.reducer'
import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'
import firebaseInit from 'utils/firebaseInit'
import { updateExtraInfos } from '../common/extraInfosCreators'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { SIGN_UP_MODAL } from 'components/new_booking/guest_flow/constants'

export const loading = () => () => {
  $('#loading-modal').addClass('visible')
}

export const loaded = () => () => {
  $('#loading-modal').removeClass('visible')
}

export const toggleDraggableMarkers = status => (dispatch, getState) => {
  getState().locations.forEach((location) => {
    if (location.marker) location.marker.setDraggable(status)
  })
}

export const changeStep = step => dispatch => dispatch(currentStepActionsCreator.changeStep(step))

export const shouldGoToStep2 = valid => stepNewLongHaulActionsCreator.shouldGoToStep2(valid)

export const setToFirstStep = () => (dispatch) => {
  Promise.resolve(
    dispatch(currentStepActionsCreator.changeStep(1)) // Return to first step
  ).then(() => dispatch(toggleDraggableMarkers(true)))
}

export const prevStep = step => (dispatch, getState) => {
  Promise.resolve(
    dispatch(currentStepActionsCreator.changeStep(getState().currentStep - (step || 1)))
  ).then(() => {
    if (getState().currentStep === 1) {
      dispatch(toggleDraggableMarkers(true))
    }
  })
}

export const showErrorPopup = (iconPopup, contentTitle, buttonPopup, config = {}) => (dispatch) => {
  dispatch(loaded())
  const info = {
    icon: iconPopup,
    title: config.title || '',
    subTitle: '',
    specialClass: config.classTitle || 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
    maxHeightIcon: config.maxHeightIcon || false,
  }
  const content = {
    title: config.content || contentTitle,
    specialClass: config.classContent || 'default-font reset m center',
  }
  return DynamicPopupWithButton(
    document.getElementById('CommonPopup'),
    info,
    content,
    buttonPopup,
  )
}

export const showErrorPopupMultiple = (elemID, iconPopup, contentTitle, buttonPopup, config = {}) => (dispatch) => {
  dispatch(loaded())
  const info = {
    icon: iconPopup,
    title: config.title || '',
    subTitle: '',
    specialClass: config.classTitle || 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
    maxHeightIcon: config.maxHeightIcon || false,
  }
  const content = {
    title: config.content || contentTitle,
    specialClass: config.classContent || 'default-font reset m center',
  }
  return DynamicPopupWithButton(
    document.getElementById(elemID),
    info,
    content,
    buttonPopup,
  )
}

const handleCreateVA = (presignedData, paymentId, customerId) => {
  const xPath = `customers/${customerId}/events/payments/${paymentId}/presigned`
  PaymentIntegrationAPI.createVAAPI(presignedData)
  PaymentIntegrationAPI.removeFirebaseChannel(xPath)
}

const handleActionFirebase = (params) => {
  const {
    snapshot,
    bookingId,
    isIncreasePrice,
    isMajor,
    transactionId,
    customerId,
    type
  } = params;

  const handleRedirect = (paymentType, providerType) => {
    let path = `/bookings/${bookingId}/payment?type=${paymentType}&provider=${providerType}`;
    if (isIncreasePrice) {
      path = `/bookings/${bookingId}/payment?type=${CUSTOMER_QUOTE_PAYMENT}&provider=${providerType}&is_major=${isMajor}`;
    }
    window.location.href = path;
  };

  if (snapshot.key === 'presigned') {
    const { presignTimeoutSetting } = params
    if (!!presignTimeoutSetting) {
      const newData = {
        bookingId,
        isIncreasePrice,
        isMajor,
        transactionId,
        customerId,
        type
      }
      Sentry.captureException('DEBUG_DLVR_48451_CALL_PRESIGN_SUCCESS', {
        contexts: { newData },
        tags: { 'booking_id': bookingId }
      });
    }
    if (type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE]) {
      handleRedirect(CUSTOMER_PAYMENT, TWO_C_TWO_P_TYPE);
    } else {
      handleCreateVA(snapshot.val(), transactionId, customerId);
    }
  }

  if (snapshot.key === 'status') {
    const status = snapshot.val();
    if (status === PAYMENT_VA_STATUS.virtualAccountCreated) {
      handleRedirect(CUSTOMER_PAYMENT, SPIL_TYPE);
    }
  }
};

const initFirebasePaymentEvent = (params) => {
  const {
    transactionId, customerId
  } = params
  let firebasePayment = null
  firebaseInit.load()
  firebasePayment = firebaseInit.listen(`customers/${customerId}/events/payments/${transactionId}`)

  firebasePayment.on('child_added', (snapshot) => {
    handleActionFirebase({ ...params, snapshot })
  })

  firebasePayment.on('child_changed', (snapshot) => {
    handleActionFirebase({ ...params, snapshot })
  })
}

const handlePayment = (state, params) => {
  try {
    const {
      transactionId, bookingId, bankCode,
    } = params
    const amount = Math.round(params.amount)
    const { extraInfos, booking } = state
    const presignTimeoutSetting = booking?.special_settings?.presign_timeout_setting
    const isIncreasePrice = booking?.payment_changed_price_status === PRICING_PAYMENT_INCREASE
    const type = booking.bankTransfer.providerName
    if (transactionId) {
      const { currentCustomer } = state
      let paramsPresigned = {
        action: 'payment',
        streamId: bookingId,
        transactionId,
        content: {
          amount,
          bankCode,
          customerName: currentCustomer.name,
          paymentInfo: I18n.t('webapp.label.ftl_booking_payment', { bookingId })
        }
      }
      if (type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE]) {
        paramsPresigned = {
          action: 'payment',
          streamId: bookingId,
          transactionId,
          content: {
            amount,
            remark: ['TH', 'th'].includes(extraInfos.country_code) ? 'Payment to Deliveree' : 'Payment to 2C2P',
          }
        }
      }
      PaymentIntegrationAPI.preSignAPIPayment(paramsPresigned, currentCustomer.id, extraInfos.country_code, type)
      const paramsForFirebase = {
        transactionId,
        customerId: currentCustomer.id,
        bookingId,
        isIncreasePrice,
        isMajor: booking.is_major,
        type,
      }
      if (!!presignTimeoutSetting) {
        setTimeout(() => {
          Sentry.captureException('DEBUG_DLVR_48451_CALL_PRESIGN_TIMEOUT', {
            contexts: { paramsForFirebase },
            tags: { 'booking_id': bookingId }
          });
        }, presignTimeoutSetting * 1000)
      }
      initFirebasePaymentEvent({ ...paramsForFirebase, presignTimeoutSetting })
    }
  } catch (err) {
    console.log('error', err)
  }
}

const handlePaymentNewBooking = (state, response) => {
  const isNewEntry = state.extraInfos?.enable_new_booking_entry_service
  const transactionId = response?.payment?.id
  const amount = response?.payment?.amount || 0
  const bookingId = isNewEntry ? response?.booking_id : response?.id
  if (transactionId) {
    const { bankTransfer } = state.booking
    const params = {
      transactionId, amount, bookingId, bankCode: bankTransfer.bankCode
    }
    handlePayment(state, params)
  }
}

const handlePaymentEditBooking = (state, newPayment) => {
  const transactionId = newPayment?.id
  const amount = newPayment?.amount || 0
  const bookingId = window.location.pathname.split('/')[2]
  if (transactionId) {
      const params = {
        transactionId, amount, bookingId, bankCode: newPayment.bank_code
      }
      handlePayment(state, params)
  }
}

const callbackAfterCreated = (response) => async (dispatch, getState) => {
  const state = getState()
  const booking = state.booking
  const isNewEntry = state.extraInfos?.enable_new_booking_entry_service
  switch (response.status) {
    case 200:
    case 201: {
      if (state.assignedDriver) {
        dispatch(addToFavoriteDrivers(state.assignedDriver))
      }
      const objectData = isNewEntry ? response?.data : response?.data?.object
      const id = isNewEntry ? objectData?.booking_id : objectData?.id
      if (
        booking?.payment_changed_price_status === PRICING_PAYMENT_INCREASE &&
        objectData?.payment?.status === PAYMENT_VA_STATUS.paid &&
        isCustomerEditBooking()
      ) {
        handlePaymentEditBooking(state, booking?.newPayment)
        return
      }
      if (!isCustomerEditBooking() && !_.isEmpty(objectData?.payment)) {
        handlePaymentNewBooking(state, objectData)
        return
      }
      if (booking.is_major === false) {
        window.localStorage.setItem('minor_booking_id', id)
        if (booking.batch_id) {
          window.location.href = '/batches'
        } else {
          window.location.href = '/bookings'
        }
      } else if (
        response?.data.batch_status &&
        response?.data?.batch_status !== BATCH_STATUS_COMPLETED &&
        response?.data?.batch_status !== BATCH_STATUS_CANCELED
      ) {
        const urlParams = new URLSearchParams(window.location.search)
        const batchId = urlParams.get('batch_id')
        window.location.href = `/batches?batch_id=${batchId}`
      } else {
        if (booking.is_major === true) {
          // The is_major_changes flag represents for waiting driver accept changes
          // It will be removed after driver accept, decline or timeout.
          window.localStorage.setItem('is_major_changes', id)
          // Enable this flag for showing the driver cancel modal...
          // ... it will be remove after navigating to booking detail
          window.localStorage.setItem('is_driver_not_available', id)
        }
        window.location.href = `/bookings/${id}`
      }
      break
    }
    case 404: {
      const body = response.data
      dispatch(
        calculate((newBooking) => {
          dispatch(bookingAgainDetailsActionsCreator.updateBookAgainSettlementDetails(newBooking?.settlement_details))
          dispatch(
            showErrorPopup(ICON_QUOTE_ID, isNewEntry ? body?.message : body.error, {
              specialClass: 'mt10 flex-center',
              list: [
                {
                  title: I18n.t('webapp.action.ok'),
                  specialClass: 'Button white green-text flex-index',
                  specialClick: () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup')),
                },
              ],
            })
          )
        })
      )
      dispatch(getCustomerCreditAmount())
      break
    }
    case 422: {
      // Error Default
      if (response.data.error) {
        if (response.data.error.code) {
          dispatch(
            showErrorPopup(ICON_CANCEL_BOOKING, isNewEntry ? response.data?.message : response.data.error || '', {
              specialClass: '',
              list: [],
            })
          )
        }
      } else {
        dispatch(
          showErrorPopup(ICON_CANCEL_BOOKING, response.data?.message || '', {
            specialClass: '',
            list: [],
          })
        )
      }
      break
    }
    case 405: {
      // Error Assign Driver
      dispatch(showErrorPopup(ICON_DRIVER_PREFERENCES, isNewEntry ? response.data?.message : response.data.error))
      break
    }
    default:
      //toastr.error(isNewEntry ? response.data?.message : response.data.error)
      dispatch(
        showErrorPopup(V2_ICON_GREEN, isNewEntry ? response.data?.message : response.data.error, {
          specialClass: 'mt10 flex-center',
          list: [
            {
              title: I18n.t('webapp.action.ok'),
              specialClass: 'Button white green-text flex-index',
              specialClick: () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup')),
            },
          ],
        })
      )
      dispatch(loaded())
      break
  }
}

export const clearCOD = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations

  const needCODLocations = _.filter(locations, ['need_cod', true])
  _.each(needCODLocations, (location) => {
    const { id } = location
    dispatch(locationsActionsCreator.updateLocation({ id, locationAttrs: { need_cod: false, cod_invoice_fees: '', cod_note: '' } }))
    dispatch(tmpLocationActionsCreator.updateTmpLocation({ id, locationAttrs: { need_cod: false, cod_invoice_fees: '', cod_note: '' } }))
  })
}

export const clearPOD = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations

  const needPODLocations = _.filter(locations, ['need_pod', true])
  _.each(needPODLocations, (location) => {
    const { id } = location
    dispatch(locationsActionsCreator.updateLocation({
      id, locationAttrs: {
        need_pod: false, pod_invoice_fees: '', pod_note: '', pre_selected: undefined
      }
    }))
    dispatch(tmpLocationActionsCreator.updateTmpLocation({
      id, locationAttrs: {
        need_pod: false, pod_invoice_fees: '', pod_note: '', pre_selected: undefined
      }
    }))
  })
}

const findDefaultPaymentMethod = (data) =>
  data.find((item) => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}

const findSelectedItem = (selectedItem, data) => data.find((item) => item.id === selectedItem?.id) || {}

const handleGoToStep3 = async (dispatch, state, callback = () => undefined) => {
  const { bookAgainDetails, extraInfos, booking } = state
  const countryCode = extraInfos?.country_code
  const paymentSettings = extraInfos?.paymentSettings

  if (booking?.special_settings?.enable_online_payment) {
    const dataAPI = await PaymentIntegrationAPI.getPaymentSettingAPI(countryCode)

    if (isPaymentBooking(bookAgainDetails?.payment_method_for_non_bp) && isCustomerEditBooking() && _.isEmpty(paymentSettings)) {
      const bookingId = window.location.pathname.split('/')[2]
      dispatch(getBookingPaymentDetailAction(bookingId, SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT], false, true))
    }

    if (dataAPI.status === 200) {
      const { data } = dataAPI
      dispatch(updateExtraInfos({ paymentSettings: [...data] }))
      if (!isCustomerEditBooking()) {
        if (_.isEmpty(paymentSettings)) {
          const defaultItem = findDefaultPaymentMethod(data)
          dispatch(updateBookingAction({ bankTransfer: defaultItem }))
        } else if (!_.isEmpty(booking.bankTransfer)) {
          const selectedItem = findSelectedItem(booking.bankTransfer, data)
          if (_.isEmpty(selectedItem)) {
            const defaultItem = findDefaultPaymentMethod(data)
            dispatch(updateBookingAction({ bankTransfer: defaultItem }))
          }
          else dispatch(updateBookingAction({ bankTransfer: selectedItem }))
        }
      }
    }
  }

  callback()
}

export const nextStep = (navigate) => (dispatch, getState) => {
  dispatch(loading())
  switch (getState().currentStep) {
    case 1: {
      const state = getState()
      const extraRequirements = state.extraServices.extraRequirements
      const dataChange = state.dataChange
      const cancelToEdit = isEditBooking() && !isCustomerEditBooking()
      if (cancelToEdit
        && (state.timeType !== state.bookAgainDetails.time_type
          || state.selectedServiceTypeID !== state.bookAgainDetails.service_type_id
          || state.selectedVehicleTypeID !== state.bookAgainDetails.vehicle_type_id
          || state.locations[0].name !== state.bookAgainDetails.locations[0].name)) {
        const bookAgainDetails = {
          auto_attachments : [],
          booking_attachments : [],
          booking_badges : [],
          booking_extra_requirements : [],
          booking_extra_requirements_negative_position : [],
          service_type_id : state.selectedServiceTypeID,
          vehicle_type_id : state.selectedVehicleTypeID,
          time_type : state.timeType,
          locations : _.cloneDeep(state.bookAgainDetails.locations),
          allow_tolls_fees : ''
        }
        bookAgainDetails.locations[0] = state.locations[0]
        dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails(bookAgainDetails))
      }

      // dataChange is wrong when edit booking flow by clearDataChangeForEditBooking in NewBookingContainer
      const hasFullDayPricing = !state.extraServices?.fullDayPricing?.amount && state.timeType === FULL_DAY
      if (_.isEmpty(extraRequirements) || dataChange || hasFullDayPricing) {
        dispatch(getExtraServicesSingle(() => {
          Promise.resolve(
            dispatch(currentStepActionsCreator.changeStep(2)),
            dispatch(toggleDraggableMarkers(false))
          ).then(() => {
            if (currentServiceType(getState())?.is_package) {
              dispatch(calculate(() => {
                Promise.resolve(
                  dispatch(currentStepActionsCreator.changeStep(2))
                ).then(
                  dispatch(loaded())
                )
              }))
            } else if (getState().timeType === FULL_DAY) {
              Promise.resolve(
                dispatch(extraServicesActionsCreator.updateCompanySetting({ allow_waiting_time_fees: false }))
              ).then(dispatch(loaded()))
            } else if (getState().timeType === LONG_HAUL) {
              Promise.resolve(
                dispatch(clearCOD())
              ).then(dispatch(loaded()))
            } else {
              dispatch(loaded())
            }
            dispatch(dataChangesActionsCreator.updateDataChange(false))
          })
        }))
      } else {
        Promise.resolve(
          dispatch(setUpBookingLocationForExtraRequirementPerLocation()),
          dispatch(currentStepActionsCreator.changeStep(2)),
          dispatch(toggleDraggableMarkers(false))
        ).then(() => {
          if (getState().timeType === LONG_HAUL) {
            Promise.resolve(
              dispatch(clearCOD())
            ).then(dispatch(loaded()))
          } else {
            dispatch(loaded())
          }
        })
      }
      return
    }
    case 2: {
      if (window.isShopping && window.isShopping()) {
        Promise.resolve(
          dispatch(currentStepActionsCreator.changeStep(3))
        ).then(
          dispatch(loaded())
        )
      } else {
        handleGoToStep3(dispatch, getState(), () => {
          dispatch(calculate(() => {
            Promise.resolve(dispatch(currentStepActionsCreator.changeStep(3)))
            .then(loaded())
          }))
        })
      }
      return
    }
    case 3: {
      if (isEditBooking()) {
        dispatch(editBooking(res => callbackAfterCreated(res)))
      } else {
        dispatch(create(res => callbackAfterCreated(res)))
      }
      break
    }
    default:
      // We shouldn't be here!
      break
  }
}
