import moment from 'moment'
import _, { isEmpty } from 'lodash'
import $ from 'jquery'
import * as Sentry from "@sentry/react"

import { TRACKING_UPDATE_DRIVER_DURATION_INTERVAL } from 'constants/trackingBookingConstants'
import { NOT_SAME_PERCENT } from '../constants/common/batchConstants'
import {
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_DELIVERY_IN_PROGRESS,
  STATUS_FLEET_ACCEPT_BOOKING,
  QUICK_CHOICE,
  FULL_DAY,
  SCHEDULE,
  NOW,
} from 'constants/bookingConstants'
import I18n from 'i18n/i18n'
import StorageKeys from 'constants/storage-keys'
import { createIframeToSyncPTL, hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import CustomerAPI from 'api/customers'
import { setAccessToken } from './crossStorage'
import * as AssignDriversUtils from 'utils/common/assignDrivers'
import { CPODUtils } from './booking/CPODUtils'

const CommonUtils = {
  playSound: (url, loopCount) => {
    const audio = document.createElement('audio')
    audio.style.display = 'none'
    audio.src = url
    audio.autoplay = true
    let count = loopCount
    audio.onended = () => {
      count -= 1
      if (count < 1) {
        audio.remove() // Remove after played.
      } else {
        audio.play()
      }
    }
    document.body.appendChild(audio)
  },
  createTmpIframe: (url) => {
    const ifrm = document.createElement('iframe')
    ifrm.setAttribute('id', 'ifrm') // assign id
    document.body.appendChild(ifrm) // to place at end of document
    ifrm.setAttribute('src', url) // assign src
    ifrm.setAttribute('frameborder', 0)
    ifrm.setAttribute('border', 0)
    ifrm.setAttribute('cellspacing', 0)
    ifrm.setAttribute('style', 'border-style: none;width: 0; height: 0; display: none;')
  },
  appName: () => {
    const arrTransportify = [
      'webapp.transportify.com.ph',
      'webapp.stg.transportify.com.ph',
      'webapp.test.transportify.com.ph',
    ]
    return _.includes(arrTransportify, window.location.hostname) ? I18n.t('app.transportify') : I18n.t('app.deliveree')
  },
  isTransportify: () => {
    const hostNames = [
      'webapp.transportify.com.ph',
      'webapp.stg.transportify.com.ph',
      'webapp.test.transportify.com.ph',
    ]
    return _.includes(hostNames, window.location.hostname)
  },
  formatMaskedPhone: (phone) => {
    const items = phone.split(',')
    if (items.length > 1) {
      return `${items.join(` (${I18n.t('webapp.preference_driver.phone_ext')}`)})`
    }
    return phone
  },
  getUpdateDriverDurationInterval: (duration) => {
    const fitnessInterval = _.find(TRACKING_UPDATE_DRIVER_DURATION_INTERVAL, (item) => item.duration <= duration)
    if (!_.isUndefined(fitnessInterval)) {
      return fitnessInterval.interval
    }
    return TRACKING_UPDATE_DRIVER_DURATION_INTERVAL[0].interval
  },
  shouldShowDriverEstimation: (bookingStatus, pickupTime, sendingEtaSmsPeriod) => {
    switch (bookingStatus) {
      case STATUS_DELIVERY_IN_PROGRESS:
        return true
      case STATUS_DRIVER_ACCEPT_BOOKING:
      case STATUS_FLEET_ACCEPT_BOOKING: {
        return moment().isSameOrAfter(moment(pickupTime, 'X').subtract(sendingEtaSmsPeriod, 'hour'))
      }
      default:
        return false
    }
  },
  converImageToBase64Url: (img) => {
    function toDataURL(url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    }
    return new Promise((resolve) => {
      toDataURL(img, (dataUrl) => {
        resolve(dataUrl)
      })
    })
  },
  replaceTextWithDotted: (name, config) => {
    if (_.size(name) > config.length) {
      return `${name.substring(0, config.length)}${config.dotted}`
    }
    return name
  },
  getTwilloChatToken: () => {
    const twilloChatToken = $('#twillo_chat_token').val()
    return twilloChatToken
  },
  resolveMoengagePending: (trackingFunc, eventName, data) => {
    const timeoutPromise = new Promise((resolve) =>
      setTimeout(() => {
        resolve('timeOut')
      }, 1000)
    );

    return Promise.race([
      trackingFunc,
      timeoutPromise,
    ]).then(result => {
      if (result === 'timeOut') {
        if (!isEmpty(data)) {
          Sentry.captureException(eventName, {
            contexts: { data }
          });
        } else {
          Sentry.captureException(eventName)
        }
      }
    });
  },
  resolveLogOutSuccess: (clearTokenFunc) => {
    const timeoutPromise = new Promise((resolve) =>
      setTimeout(() => {
        resolve('timeOut')
      }, 2000)
    );

    return Promise.race([
      clearTokenFunc,
      timeoutPromise,
    ]).then(result => {
      if (result === 'timeOut') Sentry.captureException('LOG_OUT_FAILED');
    });
  },
  moengageUserAttribute: async (
    customerId,
    firstName,
    lastName,
    userName,
    email,
    phone,
    companyID,
    countryCode,
    isLogin,
    currentStep
  ) => {
    const companyId = companyID || 'N/A'
    const switchAccountId = localStorage.getItem('switch_account')
    const id = companyID ? `${customerId}_${companyID}` : customerId
    if (switchAccountId) {
      localStorage.removeItem('switch_account')
    }
    try {
      const dataTracking = {
        id, firstName, lastName, userName, email, phone, companyId, countryCode, isLogin: true, language: I18n.locale
      }
      await CommonUtils.resolveMoengagePending(
        window.Moengage.add_unique_user_id(id),
        'add_unique_user_id',
        { id }
      )
      await CommonUtils.resolveMoengagePending(Promise.all([
        window.Moengage.add_first_name(firstName),
        window.Moengage.add_last_name(lastName),
        window.Moengage.add_user_name(userName),
        window.Moengage.add_email(email),
        window.Moengage.add_mobile(phone),
        window.Moengage.add_user_attribute('Company ID', companyId),
        window.Moengage.add_user_attribute('Country Code', countryCode),
        window.Moengage.add_user_attribute('Is Logined', true),
        window.Moengage.add_user_attribute('Language', I18n.locale),
      ]), 'moengageUserAttribute function', dataTracking)
      // window.Moengage.add_user_attribute('Credit Amount', creditAmount)
      if (isLogin || switchAccountId) {
        if (switchAccountId) {
          await CommonUtils.moengageTrackEvent('User Login')
        } else {
          await CommonUtils.moengageTrackEvent('User Login', {
            'At Step': CommonUtils.getAtStepAttributeMoengage(currentStep),
          })
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  getIsEnabledChatWithFleet(booking) {
    const fleetBeta = booking.fleet_partner?.beta || false
    const checkBetaChatWithFleet = fleetBeta ? STATUS_FLEET_ACCEPT_BOOKING : ''
    const listCheckBpChatWithFleet = [checkBetaChatWithFleet, STATUS_DRIVER_ACCEPT_BOOKING, STATUS_DELIVERY_IN_PROGRESS]

    return listCheckBpChatWithFleet.includes(booking.status)
  },
  removeFileExtension: (fileName) => {
    if (fileName) {
      const splitName = fileName.split('.')
      if (splitName.length > 1) {
        splitName.pop()
      }
      return splitName.join('.')
    }
    return ''
  },
  handleTrackMoengage: async (data, currentStep) => {
    if (data) {
      const {
        id,
        last_name: lastName,
        first_name: firstName,
        name,
        email,
        phone,
        current_company_id: companyID,
        country_code: countryCode,
      } = data
      const removePlusInPhone = phone && phone.slice(1)
      await CommonUtils.moengageUserAttribute(
        id,
        firstName,
        lastName,
        name,
        email,
        removePlusInPhone,
        companyID,
        countryCode,
        true,
        currentStep
      )
    }
  },
  handleTrackTimeTypeMoengage: (timetype) => {
    switch (timetype) {
      case QUICK_CHOICE:
      case NOW:
        window.Moengage.track_event('Quick Choices Selected')
        break
      case SCHEDULE:
        window.Moengage.track_event('Schedule Time Type Selected')
        break
      case FULL_DAY:
        window.Moengage.track_event('Full Day Time Type Selected')
        break
      default:
        break
    }
  },
  setLanguage: (lang) => {
    localStorage.setItem(StorageKeys.LANGUAGE, lang)
  },
  getLanguage: () => {
    return localStorage.getItem(StorageKeys.LANGUAGE)
  },
  getAtStepAttributeMoengage: (currentStep) => {
    let result = 'nav bar (web)'
    switch (currentStep) {
      case 2:
        result = 'favorite drivers'
        break
      case 3:
        result = 'FTL step 3'
        break
      default:
        break
    }
    return result
  },
  getMoengageId: (currentCustomer, id) => {
    if (currentCustomer.last_login_employ_id) {
      if (id === 0) {
        // Case bp to nonbp
        return {
          oldId: `${currentCustomer.id}_${currentCustomer.last_login_employ_id}`,
          newId: currentCustomer.id,
        }
      }
      // Case bp to bp
      return {
        oldId: `${currentCustomer.id}_${currentCustomer.last_login_employ_id}`,
        newId: currentCustomer.id === id ? id : `${currentCustomer.id}_${id}`,
      }
    }
    // Case non-bp to bp
    return {
      oldId: currentCustomer.id,
      newId: `${currentCustomer.id}_${id}`,
    }
  },
  getCashBackPercent: (bookings) => {
    let cashBackPercent = null
    // get list cashback not null
    const listCashBackNotNull = bookings.filter((val) => val.cash_back_reward)
    const isAllEnoughData = bookings.every((val) => val.enough_data_check_cashback)
    if (listCashBackNotNull.length > 0 && isAllEnoughData) {
      const firstCashBackPercent = listCashBackNotNull[0]?.cash_back_reward
      cashBackPercent = listCashBackNotNull.every((booking) => booking.cash_back_reward === firstCashBackPercent)
        ? firstCashBackPercent
        : NOT_SAME_PERCENT
    }
    return cashBackPercent
  },
  checkCashbackUpdate: (index, prevBooking, bookings) => {
    const isChangeLocation = !_.isEqual(prevBooking[index]?.locations, bookings[index]?.locations)
    const isChangeTimeType = !_.isEqual(prevBooking[index]?.time_type_option, bookings[index]?.time_type_option)
    const isChangeVehicleTypeId = !_.isEqual(prevBooking[index]?.vehicle_type, bookings[index]?.vehicle_type)
    return isChangeLocation || isChangeTimeType || isChangeVehicleTypeId
  },
  isSingle: () => ['/', '/bookings', '/bookings/new'].includes(window.location.pathname),
  isMultiple: () => ['/bookings/multiple'].includes(window.location.pathname),
  isBatchEZ: () => ['/batches/ez_spread_sheet'].includes(window.location.pathname),
  isSmartLoad: () => ['/batches/smart_load_planner'].includes(window.location.pathname),
  isShare: () => window.location.pathname.split('/')?.[3] === 'share',
  isFollow: () => window.location.pathname.split('/')?.[3] === 'follow',
  isTracking: () => window.location.pathname.split('/')?.[3] === 'tracking',
  walletUrl: (extraInfos, currentCustomer) => {
    const enabledEwallet = extraInfos?.enabled_ewallet
    if(!enabledEwallet) return null
    if(currentCustomer.last_login_employ_id) {
      return '/business/credit_balances'
    }
    return '/credit_balances'
  },
  goToNewLocation: (path) => {
    window.location.href = path
  },
  moengageDestroySession: (id) => CommonUtils.resolveMoengagePending(
    window.Moengage.destroy_session(),
    'destroy_session',
    { id }
  ),
  handleSignOut: async (callback = () => undefined) => {
    showLoading()
    const res = await CustomerAPI.signOut()
    if (res?.status === 204) {
      // Remove access token to storage
      await CommonUtils.resolveLogOutSuccess(setAccessToken(''))
      createIframeToSyncPTL('isLogout=true', async () => {
        try {
          await CommonUtils.moengageDestroySession()
          if (window?.fcWidget?.user) {
            await window.fcWidget.user.clear()
          }
        } finally {
          CommonUtils.setLanguage('en')
          callback()
        }
      })
    } else {
      hideLoading()
    }
  },
  moengageTrackEvent: (eventName, data) => CommonUtils.resolveMoengagePending(
    window.Moengage.track_event(eventName, data),
    eventName,
    data,
  ),
  isNeedPODCOD: (locations) => _.isObject(_.find(locations, location => location.need_cod || location.need_pod)),
  isNeedCOD: (locations) => _.isObject(_.find(locations, location => location.need_cod)),
  isNeedPOD: (locations, checkLocations) => _.isObject(_.find(locations, location => location.need_pod))
    && checkLocations && checkLocations.cod_pod,
  isNewGenPod: (locations, checkLocations, bookAgainDetails) => CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails })
    && CommonUtils.isNeedPOD(locations, checkLocations),
  getParamsForLoadFavoriteCommon: (props, loadingMore = true) => {
    const {
      currentCustomer,
      selectedVehicleTypeID,
      timeType,
      extraInfos,
      extraServices,
      locations,
      checkLocations,
      bookAgainDetails,
      booking: {
        pagination
      }
    } = props
    const extraRequirementIds = AssignDriversUtils.getExtraRequirementIds(extraServices)
    const currentPage = _.get(pagination, 'page', 0)

    return {
      authentication_token: currentCustomer.authentication_token,
      vehicle_type_id: selectedVehicleTypeID,
      time_type: timeType,
      company_id: currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id,
      area_id: extraInfos.area_id,
      country_code: extraInfos.country_code,
      include_fleet_driver: true,
      extra_requirement_ids: extraRequirementIds,
      extra_requirement_locations_ids: AssignDriversUtils.getExtraRequirementsLocationsListID(locations),
      badges: AssignDriversUtils.getBadgesListID(extraServices),
      reimbursement_ids: AssignDriversUtils.getReimbursementListID(extraServices),
      page: loadingMore ? currentPage + 1 : 1,
      loadingMore,
      location_need_cod: CommonUtils.isNeedCOD(locations),
      location_need_pod: CommonUtils.isNeedPOD(locations, checkLocations),
      new_gen_pod: CommonUtils.isNewGenPod(locations, checkLocations, bookAgainDetails),
    }
  }
}

export default CommonUtils
