import React, { useEffect } from 'react'
import routes from './routes'
import { Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { APPROVED_STATUS } from 'constants/headerConstant'
import { isEmpty } from 'lodash-es'
import { getCompanyByEmployId, getCurrentEmploy } from 'utils/booking/common'
import paths from './paths'
import { useAppSelector } from 'store/store'

const RouteList = () => {
  const currentCustomer = useAppSelector((state) => state.currentCustomer)
  const extraInfos = useAppSelector((state) => state.extraInfos)
  const accountModal = useAppSelector((s) => s.accountModal)

  let currentEmploy: any = {}
  let company: any = {}
  if (!isEmpty(currentCustomer?.employs) && currentCustomer?.last_login_employ_id) {
    currentEmploy = getCurrentEmploy(currentCustomer?.employs, currentCustomer?.last_login_employ_id)
    company = getCompanyByEmployId(currentCustomer?.companies, currentEmploy?.company_id)
  } else {
    currentEmploy = { ...currentCustomer }
  }
  const routeList = Object.keys(routes).map((key) => routes[key])
  const enabledEWallet = extraInfos?.enabled_ewallet
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const bpWallet = currentCustomer?.last_login_employ_id && enabledEWallet?.includes('business')
  const nonBpWallet = !currentCustomer?.last_login_employ_id && enabledEWallet?.includes('individual')
  let isShow = extraInfos?.show_api_dashboard
  if (currentCustomer?.last_login_employ_id && !isEmpty(company)) {
    const { status, show_api_dashboard: showApiDashboard } = company
    isShow = status === APPROVED_STATUS && showApiDashboard
  }
  const routeListRender = routeList.filter((i) => {
    const { path } = i
    const pattern = path || ''

    if (!nonBpWallet && matchPath(pattern, paths.WALLET)) {
      return false
    }
    if (!bpWallet && matchPath(pattern, paths.WALLET_BUSINESS)) {
      return false
    }
    if (!isShow && matchPath(pattern, paths.API_DASHBOARD)) {
      return false
    }
    if (!isShow && matchPath(pattern, paths.TEST_MY_BOOKING)) {
      return false
    }
    if (currentCustomer.id && !accountModal && matchPath(pattern, paths.SIGN_IN)) {
      return false
    }
    if (currentCustomer.id && !accountModal && matchPath(pattern, paths.SIGN_UP)) {
      return false
    }
    return true
  })
  const pathNotInList = !routeListRender.some((route) => {
    return matchPath(route.path || '', pathname)
  })

  useEffect(() => {
    if (pathNotInList) navigate('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathNotInList])
  
  
  return (
    <Routes>
      {routeListRender.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          caseSensitive={route.caseSensitive}
          element={route.isPrivate ? <PrivateRoute component={route.element} /> : route.element}
        />
      ))}
    </Routes>
  )
}

export default RouteList
